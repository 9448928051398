<template>
  <div class="mien">
    <OfficialHead ref="header" :homePageSetting="homePageSetting" />
    <!-- banner start -->
    <div class="banner" :style="{ marginBottom: swiperList.length && imagesList.length ? '-260px' : '0px' }">
      <el-image :src="require('@/assets/banner/mien-banner.png')" fit="cover" class="banner-img" lazy />
    </div>
    <!-- banner end -->
    <!-- hot start -->
    <div class="hot">
      <div class="container" v-if="swiperList.length">
        <div class="row">
          <div class="col">
            <div class="hot-title">热门图片</div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="d-none d-lg-block hot-swiper">
              <swiper
                ref="hot-swiper"
                :options="hotSwiperOptions"
              >
                <swiper-slide v-for="item of swiperList" :key="item.id">
                  <div class="slide-wrap">
                    <el-image :src="item.picture" class="slide-img" fit="cover" @click="linkTo(item.id)" />
                  </div>
                </swiper-slide>
                <div class="swiper-pagination swiper-pagination-progressbar" slot="pagination"></div>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
            <div class="d-lg-none hot-swiper">
              <swiper
                ref="hot-swiper"
                :options="hotSwiperSmOptions"
              >
                <swiper-slide v-for="item of swiperList" :key="item.id">
                  <div class="slide-wrap">
                    <el-image :src="item.picture" class="slide-img" fit="cover" />
                  </div>
                </swiper-slide>
                <div class="swiper-pagination swiper-pagination-progressbar" slot="pagination"></div>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- hot end -->
    <!-- brilliant start -->
    <div class="brilliant">
      <div class="container" v-if="imagesList.length">
        <div class="row">
          <div class="col-12 col-lg-6" v-for="(item, index) of imagesList" :key="index">
            <div class="brilliant-title" v-if="index === 0">
              <div class="title-wrap">
                <el-image :src="require('@/assets/dev/mein-title.png')" class="title-img" lazy />
              </div>
            </div>
            <div class="brilliant-list">
              <div class="list-item" v-for="subItem of item" :key="subItem.id" @click="linkTo(subItem.id)">
                <el-image :src="subItem.picture" class="item-img" lazy />
                <div class="item-content">
                  <span class="content-text">{{ subItem.title }}</span>
                </div>
              </div>
            </div>
            <a class="brilliant-more" v-if="index === 1" :href="`/bigPictureNews?id=${imagesQuery.articleListId}`" target="_blank">
              <img :src="require('@/assets/dev/mein-more.png')" class="more-img">
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- brilliant end -->
    <OfficialFoot :homePageSetting="homePageSetting" />
  </div>
</template>

<script>
import { getHomePageSetting, setSeo } from '../api/defaultDataModel'
import OfficialHead from './OfficialHead'
import OfficialFoot from './OfficialFoot'

export default {
  name: 'Mien',
  components: {
    OfficialHead,
    OfficialFoot
  },
  data () {
    return {
      homePageSetting: getHomePageSetting(),
      hotSwiperOptions: {
        slidesPerView: 2.3,
        spaceBetween: 20,
        freeMode: false,
        // speed: 10000,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'progressbar'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        observeParents: true
      },
      hotSwiperSmOptions: {
        slidesPerView: 1.2,
        spaceBetween: 20,
        pagination: {
          el: '.swiper-pagination',
          type: 'progressbar'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperList: [],
      imagesList: [],
      swiperQuery: {
        page: 1,
        pageSize: 10,
        articleListId: null
      },
      imagesQuery: {
        page: 1,
        pageSize: 8,
        articleListId: null
      }
    }
  },
  created () {
    const { sid, iid } = this.$route.query
    const status = sid && iid && /^\d{15,}$/.test(sid) && /^\d{15,}$/.test(iid)
    if (status) {
      this.swiperQuery.articleListId = sid
      this.imagesQuery.articleListId = iid
      this.getSetting()
      this.querySwiperList()
      this.queryImagesList()
    } else {
      this.$router.push({ path: '/' })
    }
  },
  mounted() {
    window.removeEventListener('scroll', this.$refs.header.handleScroll)
    this.$refs.header.navigationHeaderClass = ['header', 'header-fixed']
  },
  methods: {
    linkTo(id) {
      const { href } = this.$router.resolve({ path: `/importantDetail?id=${id}` })
      window.open(href, '_blank')
    },
    async getSetting () {
      const res = await this.$http.get(this.TzConfig.BASE_URL + '/h5/homePageSetting/getSetting')
      const { data, code } = res.data
      if (code === 8000) {
        this.homePageSetting = data
        setSeo(
          this.homePageSetting.seoTitle,
          this.homePageSetting.seoDescription,
          this.homePageSetting.seoKeyword
        )
      }
    },
    async querySwiperList () {
      const res = await this.$http.get(`${this.TzConfig.BASE_URL}/h5/article/list`, { params: this.swiperQuery })
      if (res.status === 200) {
        const { code, data } = res.data
        if (code === 8000) {
          const { list } = data
          if (Array.isArray(list)) {
            this.swiperList = list
          }
        }
      }
    },
    async queryImagesList () {
      const res = await this.$http.get(`${this.TzConfig.BASE_URL}/h5/article/list`, { params: this.imagesQuery })
      if (res.status === 200) {
        const { code, data } = res.data
        if (code === 8000) {
          const { list } = data
          if (Array.isArray(list)) {
            this.imagesList = this.handlePages(list)
          }
        }
      }
    },
    handlePages (list) {
      const pages = []
      list.forEach((item, index) => {
        const page = Math.floor(index / 4)
        if (!pages[page]) {
          pages[page] = []
        }
        pages[page].push(item)
      })
      return pages
    }
  }
}
</script>

<style lang="less" scoped>
  @import '../assets/css/font.css';
  .mien {
    width: 100%;
    min-height: 100vh;
    padding-top: 88px;
    font-family: pingFangSC-Medium !important;
    color: #222222;
    .common-mt {
      margin-top: 80px;
    }
    .banner {
      width: 100%;
      height: 700px;
      .banner-img {
        width: 100%;
        height: 100%;
      }
    }
    .hot {
      .hot-title {
        margin-bottom: 10px;
        font-size: 32px;
        color: #ffffff;
      }
      .hot-swiper {
        .swiper-container {
          padding-bottom: 60px;
          .swiper-slide {
            .slide-wrap {
              width: 100%;
              height: 320px;
            }
            .slide-img {
              width: 100%;
              height: 100%;
              cursor: pointer;
            }
          }
          .swiper-button-prev,
          .swiper-button-next {
            top: inherit;
            left: 0;
            bottom: 0;
            width: 40px;
            height: 40px;
          }
          .swiper-button-prev {
            -moz-transform:scaleX(-1);
            -webkit-transform:scaleX(-1);
            -o-transform:scaleX(-1);
            transform:scaleX(-1);
            background: url('../assets/dev/mein-next.png') no-repeat;
            background-position: 0 0;
            background-size: 100%;
          }
          .swiper-button-next {
            left: 50px;
            background: url('../assets/dev/mein-next.png') no-repeat;
            background-position: 0 0;
            background-size: 100%;
          }
        }
        .swiper-container-horizontal>.swiper-pagination-progressbar,
        .swiper-container-vertical>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
          left: 110px;
          bottom: 17px;
          width: 80%;
          height: 3px;
        }
        .swiper-pagination {
          background: #dedede;
          position: absolute;
          /deep/ .swiper-pagination-progressbar-fill {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            -webkit-transform: scale(0);
            -ms-transform: scale(0);
            transform: scale(0);
            -webkit-transform-origin: left top;
            -ms-transform-origin: left top;
            transform-origin: left top;
            background: #0b46b1;
          }
        }
      }
    }
    .brilliant {
      position: relative;
      margin-top: 90px;
      .brilliant-title {
        margin-bottom: 60px;
        .title-wrap {
          width: 232px;
          .title-img {
            width: 100%;
          }
        }
      }
      .brilliant-list {
        .list-item {
          position: relative;
          height: 360px;
          margin-bottom: 80px;
          cursor: pointer;
          &:nth-last-of-type(1) {
            margin-bottom: 0;
          }
          .item-img {
            width: 100%;
            height: 100%;
          }
          .item-content {
            position: absolute;
            bottom: -45px;
            right: 0;
            display: flex;
            align-items: center;
            width: 80%;
            height: 90px;
            line-height: 32px;
            box-sizing: border-box;
            padding: 12px;
            background: #0b46b1;
            .content-text {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              font-size: 18px;
              font-weight: 700;
              color: #ffffff;
            }
          }
        }
      }
      .brilliant-more {
        display: flex;
        justify-content: flex-end;
        margin-top: 120px;
      }
    }
    .footer {
      margin-top: 120px;
    }
  }
  @media (max-width: 992px) {
    .mien {
      .brilliant {
        .col-12 {
          &:nth-last-of-type(1) {
            .brilliant-list {
              margin-top: 80px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 576px) {
    .mien {
      padding-top: 64px;
      .banner {
        height: 360px;
        margin-bottom: -120px;
      }
      .hot {
        .hot-title {
          font-size: 24px;
          font-weight: 700;
        }
        .hot-swiper {
          .swiper-container {
            .swiper-slide {
              .slide-wrap {
                height: 180px;
              }
            }
            .swiper-button-prev, .swiper-button-next {
              width: 32px;
              height: 32px;
            }
            .swiper-button-next {
              left: 40px;
            }
          }
          .swiper-pagination {
            left: 86px !important;
          }
        }
      }
      .brilliant {
        margin-top: 60px;
        .brilliant-title {
          .title-wrap {
            .title-img {
              width: 80%;
            }
          }
        }
        .brilliant-list {
          .list-item {
            height: 260px;
            .item-content {
              bottom: -35px;
              height: 72px;
              line-height: 24px;
              .content-text {
                font-size: 14px;
              }
            }
          }
        }
      }
      .footer {
        margin-top: 80px;
      }
    }
  }
</style>
